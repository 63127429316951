.textApply {
  font-weight: 500;
}

.textTotalCount {
  margin-left: var(--space-xxs);

  font-weight: 400;
}

.footerAdditional {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--space-n);

  font-size: var(--size-s);
  line-height: 1.5;
}

.reset {
  white-space: nowrap;
}

@media (--mobile-m) {
  .textApply {
    font-size: var(--size-n);
  }

  .textTotalCount {
    font-size: var(--size-s);
  }
}

@media (--mobile) {
  .footerAdditional {
    margin-top: var(--space-s);
  }
}
