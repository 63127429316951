.wrapper {
  max-width: 492px;
}

.content {
  margin: 0;
}

@media (--mobile) {
  .wrapper {
    max-width: 100%;
  }
}
