.group {
  padding-top: var(--space-n);
  padding-bottom: var(--space-n);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &.collapsed {
    .arrow {
      transform: rotate(-180deg);
    }
  }

  &.withoutTitle {
    .content {
      padding-top: 0;
    }
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    color: var(--color-dark-gray);
  }

  &:hover .arrow {
    background-image: url('./arrowHover.svg');
  }
}

.title {
  margin-right: var(--space-xs);
  overflow: hidden;

  font-weight: 500;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 50%;
}

.selectedText {
  overflow: hidden;

  color: var(--color-dark-gray);

  font-size: var(--size-s);
  line-height: 1.5;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow {
  flex-shrink: 0;
  width: 13px;
  height: 8px;
  margin-left: var(--space-xs);

  background-image: url('./arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;

  transition: transform 0.2s;
}

.content {
  padding-top: var(--space-n);
}

.item {
  &:not(&:first-child) {
    padding-top: var(--space-n);
  }

  &:not(&:last-child) {
    padding-bottom: var(--space-n);

    border-bottom: 1px solid var(--color-light-gray);
  }
}
