.secondary.collapsed {
  & .mixed {
    margin-top: var(--space-n);
  }

  & .icon {
    transform: rotate(180deg);
  }

  & .checked {
    margin-top: var(--space-n);
  }
}

.checked {
  margin-top: var(--space-n);
}

.mixed,
.secondary {
  transition: margin 0.3s;
}

.showMore {
  display: inline-flex;
  gap: var(--space-xxs);
  align-items: center;
  margin-top: var(--space-n);

  color: var(--color-dark-gray);

  &.disabled {
    color: var(--color-medium-gray);

    cursor: default;

    & .icon {
      background-image: url('./arrowDownDisabled.svg');
    }
  }

  &:not(.disabled):hover {
    color: var(--color-black);

    cursor: pointer;

    & .icon {
      background-image: url('./arrowDownHover.svg');
    }
  }
}

.icon {
  width: 10px;
  height: 10px;

  background-image: url('./arrowDown.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  transition: transform 0.4s;
}
