.row {
  display: block;

  &:hover {
    cursor: pointer;
  }

  &.colored {
    .box {
      position: relative;

      width: 30px;
      height: 30px;
      margin: 4px;
      margin-right: var(--space-s);

      border: 0;
      border-radius: 50%;

      &.isWhite {
        border: 1px solid var(--color-medium-gray);
      }
    }

    .check {
      background: none;
    }

    .control:checked ~ .container {
      .box {
        border-color: var(--color-medium-gray);

        .check {
          position: absolute;

          width: 36px;
          height: 36px;

          border: 1px solid var(--color-dark-gray);
          border-radius: 50%;
        }
      }

      &.disabled .check {
        border: none;
      }
    }
  }
}

.control {
  position: absolute;
  left: -9999px;

  visibility: hidden;
}

.container {
  display: flex;
  align-items: center;

  &.disabled {
    &:hover {
      cursor: default;
    }

    .box {
      background-color: var(--color-light-gray);
      border: 1px solid var(--color-medium-gray);

      &:not(&.checked):hover {
        border-color: var(--color-medium-gray);

        .check {
          color: var(--color-light-gray);
        }
      }

      &.checked:hover {
        background-color: var(--color-light-gray);
        border: 1px solid var(--color-medium-gray);
      }
    }

    .colorBackground {
      position: absolute;

      width: 30px;
      height: 30px;

      background-color: var(--color-white);
      opacity: 0.7;
    }

    .check {
      color: var(--color-light-gray);
    }

    .text {
      color: var(--color-medium-gray);
    }
  }
}

.box {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;

  margin-right: var(--space-xs);

  border: 1px solid var(--color-medium-gray);
  border-radius: 4px;

  transition: background-color 0.2s;

  &:not(&.checked):hover {
    border-color: var(--color-dark-gray);

    .check {
      color: var(--color-dark-gray);
    }
  }

  &.checked {
    &:hover {
      background-color: var(--color-black);
      border: none;
    }
  }

  &.checked {
    &:not(focus) {
      background-color: var(--color-grafit);
      border: none;
    }
  }

  &.color {
    .iconCheck {
      display: none;
    }
  }
}

.check {
  display: flex;
  width: 14px;
  height: 10px;

  color: var(--color-white);

  transition: color 0.2s;
}

.text {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (--mobile-m) {
  .box {
    &:not(&.checked):hover {
      .check {
        color: var(--color-white);
      }
    }
  }
}
