.content {
  padding: 0 0 var(--space-xxl);
}

.group {
  border-bottom: 1px solid var(--color-light-gray);

  &.disable {
    .checkboxGroup,
    .switch {
      pointer-events: none;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}

.switchGroup {
  display: flex;
  align-items: center;

  font-size: var(--size-n);
  line-height: 1.5;
}

.switch {
  margin-right: var(--space-xs);
}

@media (--mobile) {
  .content {
    padding: 0 0 var(--space-n);
  }
}
