.container {
  position: relative;

  height: 188px;

  font-size: var(--size-s);
  line-height: 1.5;

  transition: height 1.7s;
}

.success {
  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: var(--space-m);

  background-color: var(--color-light-gray);
  opacity: 0;

  transition: opacity 1.2s;

  pointer-events: none;

  &.visible {
    position: relative;

    opacity: 1;

    pointer-events: auto;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: var(--size-n);
  line-height: 1.5;
}

.text {
  color: var(--color-dark-gray);
  font-size: var(--size-s);
  line-height: 1.5;
}

.cnControl {
  padding: 11px 0;
}

.iconSuccess {
  width: 25px;
  height: 25px;
  margin-right: var(--space-s);

  color: var(--color-green);
}

.cross {
  width: 12px;
  height: 12px;

  .icon {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;

    .icon {
      color: var(--color-dark-gray);
    }
  }
}

.step {
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  box-sizing: border-box;
  width: 100%;
  height: 188px;
  padding: var(--space-m);

  background-color: var(--color-light-gray);
  opacity: 0;

  transition: opacity 0.6s;

  pointer-events: none;

  &.visibleInitial {
    top: 0;

    opacity: 1;

    pointer-events: auto;
  }

  &.visibleMessage {
    opacity: 1;

    pointer-events: auto;
  }
}

.stepButton {
  height: 42px;
}

@media (--mobile-m) {
  .cnControl {
    height: 40px;
  }
}
